export default {
  prev: "Dastlabki sahifa",
  next: "Keyingi sahifa",
  listReference: "Maʼlumotnomalar/klassifikatorlar roʼyxati",
  responseOrg: "Masʼul tashkilot",
  view: "Turi",
  added: "Qoʼshilgan",
  update: "Yangilangan",
  updates: "Yangilash",
  noUpdates: "Yangilanmagan",
  unload: "Yuklab olish",
  viewReference: "Maʼlumotnomalar/klassifikatorlar turi",
  all: "Barchasi",
  goClassifier: "Klassifikatorga oʼtish",
  period: "Davri",
  reset: "Bekor qilish",
  registerDirector:
    "Raqamli hukumatning maʼlumotnomalar va klassifikatorlar registri",
  eGovernment: "Raqamli hukumat",
  total: "Jami",
  docs: "hujjatlar",
  changes: "Oʼzgarishlar",
  inDocuments: "hujjatlardagi",
  base: "Asos bo‘luvchi xujjat",
  archive: "Аrxiv",
  new: "Yangi",
  statistics: "Statistika",
  downloadCount: "Yuklab olishlar soni",
  requestNewClass: "Yangi klassifikatorga soʼrov",
  name: "Nomi",
  phoneNumber: "Telefon raqami",
  responsibleAgency: "Mas'ul idoralar",
  typeOfDirect: "Kataloglar / klassifikatorlar turlari",
  eGovDescription:
    "Elektron davlat xizmatlarini koʼrsatish va boshqa funktsiyalarni bajarishda davlat idoralari axborot tizimlarining idoralararo elektron hamkorligi doirasidagi masalalarni yechish uchun yagona talablarga muvofiq zamonaviy maʼlumotnomalar va tasniflagichlarni shakllantirish, saqlash, yangilash va taʼminlashni taʼminlaydigan davlat axborot tizimi.",

  send: "Xabar yuboring",
  fieldName: "maydon nomlari",
  value: "qiymat",
  enterPhoneNumber: "Telefon raqamingizni kiriting",
  getToken: "Tokenni oling",
  versionForVisually: "Ko'zi ojizlar uchun versiya",
  help: "Yordam",
  organization: "Tashkilotlar",
  request: "So'rov",
  feedback: "qayta aloqa",
  personalCabinet: "Shaxsiy kabinet",
  searchClassifier: "Ma'lumotnomalar va tasniflagichlardan qidirish",
  about: "Sayt haqida",
  instruction: "Ko'rsatmalar",
  chavo: "F.A.Q",
  find: "Qidirish",
  send: "Yuborish",
  enterPhoneNumber: "Telefon raqamingizni kiriting",
  siteDevaloper:
    "Sayt 'Raqamli hukumat' loyihalarini boshqarish markazi tomonidan ishlab chiqilgan",
  mitsAddress: "100047, Toshkent shahar, Mirobod tumani, Nukus ko‘chasi, 22, 22a va 24-uy",
  footerTitle: "Raqamli hukumat ma'lumotnomalari va tasniflagichlari reestri",
  tasks: "Vazifalari",
  tasks1: "Yagona reglamentlar va axborot almashish formatlari asosida davlat boshqaruvi tizimida foydalaniladigan ma'lumotnomalar va klassifikatorlarning yagona markazlashtirilgan ma'lumotlar bazasini yaratish va yuritish;",
  tasks2: "Davlat organlari bilan ma'lumotnoma axboroti va klassifikatorlar almashinuvi va idoralararo o‘zaro hamkorlikning reglamenti va formatini yaratish;",
  tasks3: "Yagona ma'lumotnomalar va klassifikatorlarning davlat organlarining axborot tizimlari bilan integratsiyalashuvini ta'minlash;",
  tasks4: "Birlashtirish tamoyillari bo‘yicha ma'lumotnoma ma'lumotlari va tasniflagichlarga kirish huquqlarining shaxsiy identifikasiyasini va tabaqalanishini ta'minlash;",
  tasks5: "Idoralararo axborot hamkorligida axborot xavfsizligi talablarini bajarish;",
  tasks6: "Davlat boshqaruvi tizimida foydalaniladigan ma'lumotnomalar va klassifikatorlarni samarali yuritish bo‘yicha xizmat funksiyalarini tashkil etish;",
  tasks7: "Klassifikatorlar va ma'lumotnomalarga xavfsiz kirishni tashkil etish;",
  tasks8: "Umummilliy klassifikatorlarni ishonchli markazlashtirilgan saqlash vositalarini takomillashtirish va samarali qidiruvni tashkil etish;",
  funct: "Funksiyalar",
  funct1: "Ma'lumotnomalar va klassifikatorlar registri tarkibiga kiruvchi ma'lumotnomalar va klassifikatorlarni yig‘ish, saqlash va ularni dolzarb holatda bo‘lish jarayonlarini ta'minlash",
  funct2: "Ma'lumotnomalar va klassifikatorlar registridan foydalanuvchilarga ma'lumotlarni, xususan, ma'lumotnomalar komponentlari atributlarining tarkibi hamda identifikatorlar to‘g‘risidagi ma'lumotlarni taqdim etish",
  funct3: "Foydalanuvchilarning so‘roviga ko‘ra Ma'lumotnomalar va klassifikatorlar registriga kiritilgan o‘zgartirishlar to‘g‘risidagi ma'lumotlarni taqdim etish",
  funct4: "Foydalanuvchilarning so‘roviga ko‘ra yagona registrga kiruvchi ma'lumotnomalar va klassifikatorlar ma'lumotlari bilan ta'minlash",
  funct5: "Foydalanuvchilarning so‘roviga ko‘ra Ma'lumotnomalar va klassifikatorlar registriga kiritilgan ma'lumotnomalar va klassifikatorlarning yangilangan ma'lumotlarini taqdim etish",
  funct6: "Ma'lumotnomalar va klassifikatorlar registriga kiritilgan ma'lumotnomalar va klassifikatorlarni loyihalash, yaratish va yuritish uchun zarur vositalar bilan ta'minlash",
  funct7: "Idoralararo axborot hamkorligida axborot xavfsizligi talablarini bajarish",
  instruction1: "Tizimdan foydalanish bo‘yicha qo‘llanmalar",
  instruction2: "Klassifikatorlar registriga qanday kirish mumkin?",
  instruction3: "Klassifikatorni qanday topish mumkin?",
  instruction4: "JSON formatida klassifikatorni qanday yuklab olish mumkin?",
  instruction5: "XML formatida klassifikatorni qanday yuklab olish mumkin?",
  instruction6: "XLS formatida klassifikatorni qanday yuklab olish mumkin?",
  instruction7: "Web-servis yordamida qanday integratsiya qilish mumkin?",
  chavo1: "Klassifikatorlar nima uchun kerak?",
  chavo2: "http://classifiers.egov.uz/ sayti nima uchun kerak?",
  chavo3: "Zarur klassifikatorni qanday topish kerak?",
  chavo4: "Klassifikator tarkibini qanday qilib ko‘rish mumkin?",
  chavo5: "Klassifikatorni qanda qilib yuklash zarur?",
  chavo6: "API nima uchun kerak?",
  chavo7: "Klassifikator APIsini qanday olish kerak?",
  chavoText1:
    "<strong>Klassifikatorlar</strong> eng muhim instrument va standartlaridan biri bo‘lib, statistik ma'lumotlarni tartibga solish, identifikasiya qilish, statistik ma'lumotlarni tizimlashtirish va uyg‘unlashtirish, ularni turli kesimlarda guruhlashtirish hamda ma'lumotlar bazalarini shakllantirishda uyg‘unlashgan yondoshuv va axborot tizimlari o‘rtasida integratsiyani amalga oishirish imkoniyatlarini beradi.",
  chavoText2:
    "Classifiers.egov.uz bu idoralar o‘rtasida o‘zaro axborot almashish samaradorligini oshirish, axborot xavfsizligi vositalari yordamida ma'lumotlarning maxfiyligi va butunligini ta'minlash hamda idoralararo o‘zaro axborot almashish jarayonida shu jumladan, moliyaviy va vaqtiy xarajatlarni, minimallashtirish uchun yaratilgan ma'lumotlarning yagona platformasi." +
    "<br><br> Shu bilan birga, platformaning muhim vazifalari quyidagilar hisoblanadi: davlat boshqaruvi tizimida klassifikator axborotnomalarini yuritish yagona mexanizmini yaratish; axborot almashish reglamenti va formatini yaratish; davlat organlari axborot tizimlari bilan yagona axborotnomalar va klassifikatorlarining integratsiyasini ta'minlash; va ishonchli umumdavlat klassifikatorlarining markazlashtirilgan holda saqlanishini takomillashtirish, va samarali qidiruvni tashkillashtirish.",
  chavoText3:
    "Qidiruv qatoriga klassifikator nomini kiriting va 'Enter' tugmasini yoki qator oxiridagi lupa belgisiga bosing. Siz klassifikatorni bir so‘z yoki butun nomi bo‘yicha topishingiz mumkin. Birinchi qidiruv so‘rovi amalga oshirilgandan so‘ng, tizim Sizga kengaytirilgan qidiruvni taklif qiladi, unda Siz so‘rovni tanlagan holda aniqlashtirishingiz mumkin: " +
    "<br><br> Axborotnoma/klassifikator turi" +
    "<br><br> Mas'ul idora" +
    "<br><br> Yangilash davri" +
    "<br><br> Yoki qidiruv qatorining tagidagi 'Axborotnoma/klassifikator turlari' vkladkasida turlar bo‘yicha qidiruvni boshlash, yoki qo‘shni 'Mas'ul idoralar' vkladkasida mas'ul idoralar bo‘yicha ",
  chavoText4:
    "Zarur klassifikator topilgandan so‘ng, Siz uning tarkibini 'Klassifikatorga o‘tish' klassifikator kitobchasining o‘ng pastki burchakdagi havolani bosgan holda ko‘rishingiz mumkin. U kursorni kitobchaning ustiga olib borgan vaqtda paydo bo‘ladi.",
  chavoText5:
    "Mashina o‘qiydigan formatdagi klassifikatorni yuklash uchun havolalar kursorni klassifikator kitobchasi ustiga olib borgan vaqtda paydo bo‘ladi. Siz 3 ta formatdan birini tanlashingiz mumkin (CSV, XML, XLS)",
  chavoText6:
    "API (ilovaning dasturiy interfeysi, amaliy dasturlashtirishning interfeysi) - bir komp'yuter dasturi boshqa dastur bilan o‘zaro ma'lumot almashish  usullarini tasvirlashdir (jarayonlar, funksiyalar, tuzilish yoki konstantlarni terish). API boshqa axborot tizimlari bilan integratsiya qilish uchun zarur.",
  chavoText7:
    "'Qo‘llanma' sahifasida APIni olish bo‘yicha batafsil qo‘llanmani o‘qing.",
  chavoTitle: "Ko'p so'raladigan savollar",
  contact: "Aloqa",
  normalVersion: "Oddiy versiya",
  notResults: "Hech qanday qidiruv natijalari topilmadi",
  email: "Электрон почта",
  notEntered: "kiritilmadi!",
  description: "Klassifikatorning tavsifi",
  errorEntered: "notoʻgʻri kiritildi!",
  messageSuccess: "Xabar muvaffaqiyatli yuborildi",
  orderBy: "O‘sish tartibida",
  orderByDescending: "Kamaytirish tartibida",
  webService: "Web-xizmati",
  users: "foydalanuvchilari",
  recordCount: "Yozuvlar soni",
};