export default {
  prev: "Предыдущая страница",
  next: "Следующая страница",
  listReference: "Список справочников/классификаторов",
  responseOrg: "Ответственное ведомство",
  view: "Вид",
  added: "Добавлен",
  update: "Обновлен",
  updates: "Обновления",
  noUpdates: "Не обновлен",
  unload: "Выгрузка",
  viewReference: "Вид справочников/классификаторов",
  all: "Все",
  goClassifier: "Перейти к классификатору",
  period: "Период",
  reset: "Сбросить",
  registerDirector:
    "Регистр справочников <br> и классификаторов цифрового правительства",
  eGovernment: "цифрового правительства",
  total: "Всего",
  docs: "документов",
  changes: "Изменений",
  inDocuments: "в документах",
  base: "Основание",
  archive: "Архив",
  new: "Новое",
  statistics: "Статистика",
  downloadCount: "Количество скачиваний",
  requestNewClass: "Запрос на новый классификатор",
  name: "Наименование",
  phoneNumber: "Телефонный номер",
  responsibleAgency: "Ответственные ведомства",
  typeOfDirect: "Виды справочников/классификаторов",
  eGovDescription:
    "Государственная информационная система, обеспечивающая формирование, хранение, актуализацию и предоставление по единым требованиям актуальных справочников и классификаторов для решения задач межведомственного электронного взаимодействия информационных систем государственных органов при оказании электронных государственных услуг и выполнении иных функций.",
  versionForVisually: "Версия для слабовидящих",
  help: "Помощь",
  organization: "Организации",
  request: "Запрос",
  feedback: "Обратная связь",
  personalCabinet: "Персональный кабинет",
  searchClassifier: "Поиск по справочникам и классификаторам",
  about: "О сайте",
  instruction: "Инструкция",
  chavo: "ЧАВО",
  find: "Найти",
  send: "Отправить",
  fieldName: "названия поля ",
  value: "значение",
  enterPhoneNumber: "Введите свой номер телефона",
  getToken: "Получить токен",
  siteDevaloper: "Сайт разработан Центром управления проектами цифрового Правительства",
  mitsAddress: "100047, город Ташкент, Мирабадский район, улица Нукус, дом 22, 22а и 24",
  footerTitle: "Регистр справочников и классификаторов цифрового правительства",
  tasks: "Задачи",
  tasks1: "Создание и ведение единой унифицированной централизованной базы справочников и классификаторов, применяемых в системе государственного управления на основе единых регламентов и форматов обмена информацией;",
  tasks2: "Создание регламента и формата обмена справочной информацией и классификаторов с государственными органами и межведомственное взаимодействие;",
  tasks3: "Обеспечение интеграции единых справочников и классификаторов с информационными системами государственных органов;",
  tasks4: "Обеспечение персональной идентификации и разграничения прав доступа к справочной информации и классификаторам на принципах унификации;",
  tasks5: "Выполнение требований обеспечения информационной безопасности при межведомственном информационном взаимодействии;",
  tasks6: "Организация сервисных функций для эффективного ведения справочников и классификаторов, применяемых в системе государственного управления;",
  tasks7: "Организация безопасного доступа к классификаторам и справочникам;",
  tasks8: "Совершенствование средств надежного централизованного хранения общегосударственных классификаторов и организация эффективного поиска.",
  funct: "Функции",
  funct1: "Обеспечение процесса сбора, хранения и поддержки в актуальном состоянии справочников и классификаторов, входящих в Регистр справочников и классификаторов",
  funct2: "Предоставление пользователям сведений из Регистра справочников и классификаторов, в частности, информации об идентификаторе, составе атрибутов компонентов справочников",
  funct3: "Предоставление пользователям по запросу сведений об изменении сведений в Регистре справочников и классификаторов",
  funct4: "Предоставление пользователям по запросу данных справочников и классификаторов, включенных в единый реестр",
  funct5: "Предоставление пользователям по запросу обновлений данных справочников и классификаторов, включенных в Регистр справочников и классификаторов",
  funct6: "Обеспечение инструментальных средств для проектирования, создания и ведения справочников и классификаторов, включенных в Регистр справочников и классификаторов",
  funct7: "Выполнения требований обеспечения информационной безопасности при межведомственном информационном взаимодействии",
  instruction1: "Инструкции по использованию системы",
  instruction2: "Как попасть на систему классификаторов?",
  instruction3: "Как найти классификатор?",
  instruction4: "Как закачать классификатор в формате JSON?",
  instruction5: "Как закачать классификатор в формате XML?",
  instruction6: "Как закачать классификатор в формате XLS?",
  instruction7: "Как интегрироваться с помощью веб сервиса?",
  chavo1: "Для чего нужны классификаторы? ",
  chavo2: "Для чего нужен сайт https://classifiers.egov.uz/?",
  chavo3: "Как найти нужный классификатор? ",
  chavo4: "Как посмотреть содержание классификатора?",
  chavo5: "Как скачать классификатор?",
  chavo6: "Для чего нужен API?",
  chavo7: "Как получить API классификатора?",
  chavoText1:
    "<strong>Классификаторы</strong> являются одним из важнейших инструментов и стандартов, позволяющих упорядочивать и идентифицировать статистические данные, обеспечивать систематизацию и унификацию статистической информации и осуществлять группирование статистических данных в различных разрезах, а также для обеспечения унифицированного подхода при формировании баз данных и осуществления интеграции между информационными системами. ",
  chavoText2:
    "Classifiers.egov.uz это единая платформа справочной информации, которая была создана для повышения эффективности информационного взаимодействия между ведомствами, для обеспечения конфиденциальности и целостности межведомственной информации с помощью средств информационной безопасности, а так же для минимизация издержек, в том числе финансовых и временных, при осуществлении межведомственного информационного взаимодействия" +
    "<br><br>Так же важными задачами платформы являются: создание единого механизма ведения справочников классификаторов, применяемых в системе государственного управления; создание регламента и формата обмена справочной информацией; обеспечение интеграции единых справочников и классификаторов с информационными системами государственных органов; и совершенствование средств надежного централизованного хранения общегосударственных классификаторов, и организация эффективного поиска.",
  chavoText3:
    "Введите название классификатора в строку поиска и нажмите «Enter», или на значок лупы в конце строки. Вы можете найти классификатор по одному слову, или по целому названию. После выполнения первого поискового запроса система предложит вам расширенный поиск, где вы сможете конкретизировать свой запрос выбрав: " +
    "<br><br> Вид справочника/классификатора " +
    "<br><br> Ответственное ведомство " +
    "<br><br> Период обновления " +
    "<br><br> Либо сразу начать поиск по видам во вкладке под поисковой строкой «Виды справочников/классификаторов», или по ответственным ведомствам в соседней вкладке «Ответственные ведомства»",
  chavoText4:
    "После того как нужный классификатор найден вы можете посмотреть его содержание нажав на ссылку в правом нижнем углу в карточке классификатор «Перейти к классификатору», которая появляется при наведении курсора на карточку. ",
  chavoText5:
    "Ссылки на скачивание классификатора в машиночитаемом формате появляются при наведении курсора на карточку классификатора. Вы можете выбрать один из 3х форматов (CSV, XML, XLS)",
  chavoText6:
    "API (программный интерфейс приложения, интерфейс прикладного программирования) - описание способов (набор классов, процедур, функций, структур или констант), которыми одна компьютерная программа может взаимодействовать с другой программой. API нужен для интеграции с другими информационными системами.",
  chavoText7:
    "Читайте подробную инструкцию получения API во вкладке «Инструкция».",
  chavoTitle: "Часто задаваемые вопросы",
  contact: "Контакт",
  normalVersion: "Обычная версия",
  notResults: "Нет результатов по запросу",
  email: "Электрон адрес",
  notEntered: "не введено!",
  description: "Описание классификатора",
  errorEntered: "введено не верно!",
  messageSuccess: "Сообщение было успешно отправлено",
  orderBy: "По возрастанию",
  orderByDescending: "По убыванию",
  webService: "Пользователи",
  users: "веб-сервиса",
  recordCount: "Количество записей",
};