import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import api from "./plugin/api";
import table from "./moduls/table";
import local from "./local";
Vue.use(Vuex);
const url = "";
const fileUrl = 'https://kinoinsayder.uz/files'

//const url = "http://127.0.0.1:2050";
//const fileUrl = 'http://127.0.0.1:2054/files';

var http = axios.create({
	baseURL: url,
});
Vue.use(api, {
	http,
});
Vue.prototype.$j = function (...keys) {
	if (!keys) return this.$store.state.lang;
	if (keys) {
		switch (keys.length) {
			case 1:
				return this.$store.state.lang[keys[0]];
			case 2:
				return this.$store.state.lang[keys[0]][keys[1]];
		}
	}
};

Vue.prototype.$table = function () {
	return this.$store.state.table;
};
const store = new Vuex.Store({
	modules: {
		table,
	},
	state: {
		url,
		fileUrl,
		lang: local.rus,
		local,
		langKod: "",
	},
	getters: {
		changeLang: (state, getters) => (_this, lang) => {
			state.langKod = lang;
			state.lang = state.local[lang];
		},
		parseDate: (state, getters) => (date, formate) => {
			return moment(date, formate).format("DD.MM.YYYY");
		},
	},
});
export default store;