export default {
  prev: "Previous page",
  next: "Next page",
  listReference: "List of directories / classifiers",
  responseOrg: "Responsible department",
  view: "Type",
  added: "Added",
  update: "Updated",
  updates: "Updates",
  noUpdates: "No updates",
  unload: "Unloading",
  viewReference: "Type of directories / classifiers",
  all: "All",
  goClassifier: "Go to classifier",
  period: "Period",
  reset: "Reset",
  registerDirector:
    "Register of directories and classifiers of Digital government",
  eGovernment: "of Digital government",
  total: "Total",
  docs: "documents",
  changes: "Changes",
  inDocuments: "to documents",
  base: "Basis",
  archive: "Archive",
  new: "New",
  statistics: "Statistics",
  downloadCount: "Number of downloads",
  requestNewClass: "Request for a new classifier",
  name: "Name",
  phoneNumber: "Phone number",
  responsibleAgency: "Responsible departments",
  typeOfDirect: "Types of directories / classifiers",
  eGovDescription:
    "The state information system, which ensures the formation, storage, updating and provision of up-to-date reference books and classifiers according to uniform requirements for solving the problems of interdepartmental electronic interaction of information systems of state bodies in the provision of electronic public services and performance of other functions.",
  versionForVisually: "Version for visually impaired",
  help: "Help",
  organization: "Departments",
  request: "Request",
  feedback: "Feedback",
  personalCabinet: "Personal cabinet",
  searchClassifier: "Search indirectories and classifiers",
  about: "About the site",
  instruction: "Instruction",
  chavo: "F.A.Q",
  find: "To find",
  send: "To send",
  fieldName: "Field names",
  value: "Value",
  enterPhoneNumber: "Enter your phone number",
  getToken: "Get a token",
  siteDevaloper: "The site is developed by the Project Management Center of the Digital Government",
  mitsAddress: "100047, Tashkent city, Mirabad district, Nukus street, 22, 22a and 24",
  footerTitle: "Register of directories and classifiers of Digital government",
  tasks: "Tasks",
  tasks1: "Creation and maintenance of a single unified centralized database of directories and classifiers used in the public administration system on the basis of uniform regulations and formats for information exchange",
  tasks2: "Creation of a regulation and format for the exchange of reference information and classifiers with government agencies and interdepartmental interaction",
  tasks3: "Ensuring the integration of unified directories and classifiers with information systems of state bodies",
  tasks4: "Ensuring personal identification and differentiation of access rights to reference information and classifiers on the principles of unification",
  tasks5: "Fulfillment of requirements for ensuring information security in interdepartmental information interaction",
  tasks6: "Organization of service functions for the effective maintenance of directories and classifiers used in the public administration system",
  tasks7: "Organization of secure access to classifiers and directories",
  tasks8: "Improving the means of reliable centralized storage of national classifiers and organizing an effective search",
  funct: "Functions",
  funct1: "Ensuring the process of collecting, storing and maintaining up-to-date reference books and classifiers included in the Register of reference books and classifiers",
  funct2: "Providing users with information from the Register of directories and classifiers, in particular, information about the identifier, the composition of the attributes of the components of directories",
  funct3: "Providing users, upon request, with information about changes in information in the Register of reference books and classifiers",
  funct4: "Providing users, upon request, with data of directories and classifiers included in the unified register",
  funct5: "Providing users, upon request, with updates to the data of directories and classifiers included in the Register of directories and classifiers",
  funct6: "Provision of tools for designing, creating and maintaining directories and classifiers included in the Register of directories and classifiers",
  funct7: "Fulfillment of information security requirements in interdepartmental information interaction",
  instruction1: "",
  instruction2: "",
  instruction3: "",
  instruction4: "",
  instruction5: "",
  instruction6: "",
  instruction7: "",
  chavo1: "What are classifiers for?",
  chavo2: "What is the site https://classifiers.egov.uz/ for?",
  chavo3: "How to find a suitable classifier?",
  chavo4: "How to view the contents of the classifier?",
  chavo5: "How to download a classifier?",
  chavo6: "What is the API for?",
  chavo7: "How do I get the classifier API?",
  chavoText1:
    "<strong>Classifiers</strong> are one of the most important tools and standards for ordering and identifying statistical data, ensuring the systematization and unification of statistical information and grouping statistical data in various sections, as well as to ensure a unified approach in the formation of databases and the implementation of integration between information systems.",
  chavoText2:
    "Classifiers.egov.uz is a unified reference information platform, which was created to improve the efficiency of information interaction between departments, to ensure the confidentiality and integrity of interdepartmental information using information security tools, as well as to minimize costs, including financial and time, when conducting interdepartmental information interaction." +
    "<br><br>Also important tasks of the platform are: creation of a unified mechanism for maintaining reference books of classifiers used in the public administration system; creation of regulations and format for the exchange of reference information; ensuring the integration of unified reference books and classifiers with the information systems of state bodies; and improving the means of secure centralized storage of nationwide classifiers, and the provision of efficient searches.",
  chavoText3:
    "Enter the name of the classifier in the search bar and press «Enter», or on the «magnifying glass» icon at the end of the line. You can find a classifier by one word, or by the whole name. After completing the first search query, the system will offer you an advanced search, where you can refine your query by choosing:" +
    "<br><br> Directory / classifier type" +
    "<br><br> Responsible department" +
    "<br><br> Renewal period" +
    "<br><br> Or immediately start the search by species in the tab under the search line «Types of directories/classifiers», or by the responsible departments in the adjacent tab «Responsible departments»",
  chavoText4:
    "After the required classifier is found, you can view its content by clicking on the link in the lower right corner of the classifier card 'Go to classifier', which appears when you hover the cursor over the card.",
  chavoText5:
    "Links to download the classifier in a machine-readable format appear when you hover the cursor over the classifier card. You can choose one of 3 formats (CSV, XML, XLS)",
  chavoText6:
    "API (application programming interface) - a description of the ways (a set of classes, procedures, functions, structures or constants) by which one computer program can interact with another program. API is needed for integration with other information systems.",
  chavoText7:
    "Read the detailed chavos for getting the API in the 'chavos' tab.",
  chavoTitle: "Frequently asked Questions",
  contact: "Contact",
  normalVersion: "Normal version",
  notResults: "No search results found.",
  email: "Email",
  notEntered: "not entered!",
  description: "Description of the classifier",
  errorEntered: "entered incorrectly!",
  messageSuccess: "The message was sent successfully",
  orderBy: "Ascending",
  orderByDescending: "Descending",
  webService: "Web service",
  users: "users",
  recordCount: "Number of records",
};