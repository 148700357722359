export default {
  prev: "Дастлабки саҳифа",
  next: "Кейинги саҳифа",
  listReference: "Маълумотномалар/классификаторлар рўйхати",
  responseOrg: "Масъул ташкилот",
  view: "Тури",
  added: "Қўшилган",
  update: "Янгиланган",
  updates: "Янгилаш",
  noUpdates: "Янгиланмаган",
  unload: "Юклаб олиш",
  viewReference: "Маълумотномалар/классификаторлар тури",
  all: "Барчаси",
  goClassifier: "Классификаторга ўтиш",
  period: "Даври",
  reset: "Бекор қилиш",
  registerDirector:
    "Рақамли ҳукуматнинг маълумотномалар ва классификаторлар регистри",
  eGovernment: "Рақамли ҳукумат",
  total: "Жами",
  docs: "ҳужжатлар",
  changes: "Ўзгаришлар",
  inDocuments: "ҳужжатлардаги",
  base: "Асос бўлувчи хужжат",
  archive: "Архив",
  new: "Янги",
  statistics: "Статистика",
  downloadCount: "Юклаб олишлар сони",
  requestNewClass: "Янги классификаторга сўров",
  name: "Номи",
  phoneNumber: "Телефон рақами",
  responsibleAgency: "Масъул идоралар",
  typeOfDirect: "Каталоглар / классификаторлар турлари",
  eGovDescription:
    "Электрон давлат хизматларини кўрсатиш ва бошқа функцияларни бажаришда давлат идоралари ахборот тизимларининг идоралараро электрон ҳамкорлиги доирасидаги масалаларни ечиш учун ягона талабларга мувофиқ замонавий маълумотномалар ва таснифлагичларни шакллантириш, сақлаш, янгилаш ва таъминлашни таъминлайдиган давлат ахборот тизими.",

  send: "Хабар юборинг",
  fieldName: "майдон номлари",
  value: "қиймат",
  enterPhoneNumber: "Телефон рақамингизни киритинг",
  getToken: "Белгини олинг",

  versionForVisually: "Кўзи ожизлар учун версия",
  help: "Ёрдам",
  organization: "Ташкилотлар",
  request: "Сўров",
  feedback: "қайта алоқаc",
  personalCabinet: "Шахсий кабинет",
  searchClassifier: "Маълумотномалар ва таснифлагичлардан қидириш",
  about: "Сайт ҳақида",
  instruction: "Кўрсатмалар",
  chavo: "Кўп сўраладиган саволлар",
  find: "Қидириш",
  send: "Юбориш",
  enterPhoneNumber: "Телефон рақамингизни киритинг",
  siteDevaloper:
    "Сайт Рақамли ҳукуматъ лойиҳаларини бошқариш маркази томонидан ишлаб чиқилган",
  mitsAddress: "100047, Тошкент шаҳар, Миробод тумани, Нукус кўчаси, 22, 22а ва 24-уй",
  footerTitle: "Рақамли ҳукумат маълумотномалари ва таснифлагичлари реестри",
  tasks: "Вазифалари",
  tasks1: "Ягона регламентлар ва ахборот алмашиш форматлари асосида давлат бошқаруви тизимида фойдаланиладиган маълумотномалар ва классификаторларнинг ягона марказлаштирилган маълумотлар базасини яратиш ва юритиш;",
  tasks2: "Давлат органлари билан маълумотнома ахбороти ва классификаторлар алмашинуви ва идоралараро ўзаро ҳамкорликнинг регламенти ва форматини яратиш;",
  tasks3: "Ягона маълумотномалар ва классификаторларнинг давлат органларининг ахборот тизимлари билан интеграциялашувини таъминлаш;",
  tasks4: "Бирлаштириш тамойиллари бўйича маълумотнома маълумотлари ва таснифлагичларга кириш ҳуқуқларининг шахсий идентификациясини ва табақаланишини таъминлаш;",
  tasks5: "Идоралараро ахборот ҳамкорлигида ахборот хавфсизлиги талабларини бажариш;",
  tasks6: "Давлат бошқаруви тизимида фойдаланиладиган маълумотномалар ва классификаторларни самарали юритиш бўйича хизмат функцияларини ташкил этиш;",
  tasks7: "Классификаторлар ва маълумотномаларга хавфсиз киришни ташкил этиш;",
  tasks8: "Умуммиллий классификаторларни ишончли марказлаштирилган сақлаш воситаларини такомиллаштириш ва самарали қидирувни ташкил этиш.",
  funct: "Функциялар",
  funct1: "•	Маълумотномалар ва классификаторлар регистри таркибига кирувчи маълумотномалар ва классификаторларни йиғиш, сақлаш ва уларни долзарб ҳолатда бўлиш жараёнларини таъминлаш",
  funct2: "•	Маълумотномалар ва классификаторлар регистридан фойдаланувчиларга маълумотларни, хусусан, маълумотномалар компонентлари атрибутларининг таркиби ҳамда идентификаторлар тўғрисидаги маълумотларни тақдим этиш",
  funct3: "•	Фойдаланувчиларнинг сўровига кўра Маълумотномалар ва классификаторлар регистрига киритилган ўзгартиришлар тўғрисидаги маълумотларни тақдим этиш",
  funct4: "•	Фойдаланувчиларнинг сўровига кўра Маълумотномалар ва классификаторлар регистрига кирувчи маълумотномалар ва классификаторлар маълумотлари билан таъминлаш",
  funct5: "•	Фойдаланувчиларнинг сўровига кўра Маълумотномалар ва классификаторлар регистрига киритилган маълумотномалар ва классификаторларнинг янгиланган маълумотларини тақдим этиш",
  funct6: "•	Маълумотномалар ва классификаторлар регистрига киритилган маълумотномалар ва классификаторларни лойиҳалаш, яратиш ва юритиш учун зарур воситалар билан таъминлаш",
  funct7: "•	Идоралараро ахборот ҳамкорлигида ахборот хавфсизлиги талабларини бажариш",
  instruction1: "Тизимдан фойдаланиш бўйича қўлланмалар",
  instruction2: "Классификаторлар регистрига қандай кириш мумкин??",
  instruction3: "Классификаторни қандай топиш мумкин?",
  instruction4: "JSON форматида классификаторни қандай юклаб олиш мумкин?",
  instruction5: "XML форматида классификаторни қандай юклаб олиш мумкин?",
  instruction6: "XLS форматида классификаторни қандай юклаб олиш мумкин?",
  instruction7: "Веб-сервис ёрдамида қандай интеграция қилиш мумкин?",
  chavo1: "Классификаторлар нима учун керак?",
  chavo2: "classifiers.egov.uz/ сайти нима учун керак?",
  chavo3: "Зарур классификаторни қандай топиш керак?",
  chavo4: "Классификатор таркибини қандай қилиб кўриш мумкин?",
  chavo5: "Классификаторни қанда қилиб юклаш зарур?",
  chavo6: "API нима учун керак?",
  chavo7: "Классификатор APIсини қандай олиш керак?",
  chavoText1:
    "<strong>Классификаторлар</strong> энг муҳим инструмент ва стандартларидан бири бўлиб, статистик маълумотларни тартибга солиш, идентификация қилиш, статистик маълумотларни тизимлаштириш ва уйғунлаштириш, уларни турли кесимларда гуруҳлаштириш ҳамда маълумотлар базаларини шакллантиришда уйғунлашган ёндошув ва ахборот тизимлари ўртасида интеграцияни амалга оишириш имкониятларини беради",
  chavoText2:
    "Classifiers.egov.uz бу идоралар ўртасида ўзаро ахборот алмашиш самарадорлигини ошириш, ахборот хавфсизлиги воситалари ёрдамида маълумотларнинг махфийлиги ва бутунлигини таъминлаш ҳамда идоралараро ўзаро ахборот алмашиш жараёнида шу жумладан, молиявий ва вақтий харажатларни, минималлаштириш учун яратилган маълумотларнинг ягона платформаси" +
    "<br><br> Шу билан бирга, платформанинг муҳим вазифалари қуйидагилар ҳисобланади: давлат бошқаруви тизимида классификатор ахборотномаларини юритиш ягона механизмини яратиш; ахборот алмашиш регламенти ва форматини яратиш; давлат органлари ахборот тизимлари билан ягона ахборотномалар ва классификаторларининг интеграциясини таъминлаш; ва ишончли умумдавлат классификаторларининг марказлаштирилган ҳолда сақланишини такомиллаштириш, ва самарали қидирувни ташкиллаштириш",
  chavoText3:
    "Қидирув қаторига классификатор номини киритинг ва 'Enter' тугмасини ёки қатор охиридаги лупа белгисига босинг. Сиз классификаторни бир сўз ёки бутун номи бўйича топишингиз мумкин. Биринчи қидирув сўрови амалга оширилгандан сўнг, тизим Сизга кенгайтирилган қидирувни таклиф қилади, унда Сиз сўровни танлаган ҳолда аниқлаштиришингиз мумкин: " +
    "<br><br> Ахборотнома/классификатор тури" +
    "<br><br> Масъул идора" +
    "<br><br> Янгилаш даври" +
    "<br><br> Ёки қидирув қаторининг тагидаги 'Ахборотнома/классификатор турлари' вкладкасида турлар бўйича қидирувни бошлаш, ёки қўшни 'Масъул идоралар' вкладкасида масъул идоралар бўйича ",
  chavoText4:
    "Зарур классификатор топилгандан сўнг, Сиз унинг таркибини 'Классификаторга ўтиш' классификатор китобчасининг ўнг пастки бурчакдаги ҳаволани босган ҳолда кўришингиз мумкин. У курсорни китобчанинг устига олиб борган вақтда пайдо бўлади.",
  chavoText5:
    "Машина ўқийдиган форматдаги классификаторни юклаш учун ҳаволалар курсорни классификатор китобчаси устига олиб борган вақтда пайдо бўлади. Сиз 3 та форматдан бирини танлашингиз мумкин (CSV, XML, XLS) ",
  chavoText6:
    "API (илованинг дастурий интерфейси, амалий дастурлаштиришнинг интерфейси) - бир компьютер дастури бошқа дастур билан ўзаро маълумот алмашиш  усулларини тасвирлашдир (жараёнлар, функциялар, тузилиш ёки константларни териш). API бошқа ахборот тизимлари билан интеграция қилиш учун зарур.",
  chavoText7:
    "'Қўлланма' саҳифасида APIни олиш бўйича батафсил қўлланмани ўқинг.",
  chavoTitle: "Кўп сўраладиган саволлар",
  contact: "Aлоқа",
  normalVersion: "Оддий версия",
  notResults: "Ҳеч қандай қидирув натижалари топилмади.",
  email: "Elektron pochta",
  notEntered: "киритилмади!",
  description: "Классификаторнинг тавсифи",
  errorEntered: "нотўғри киритилди!",
  messageSuccess: "Хабар муваффақиятли юборилди",
  orderBy: "Ўсиш тартибида",
  orderByDescending: "Камайтириш тартибида",
  webService: "Веб-хизмати",
  users: "фойдаланувчилари",
  recordCount: "Ёзувлар сони",
};