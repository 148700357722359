<template>
	<div id="app">
		<!-- Page loader -->
		<div id="preloader"></div>
		<headers></headers>
		<router-view />
		<!-- <footers></footers> -->
	</div>
</template>

<script>
import Footers from "./components/layout/footer.vue";
import Headers from "./components/layout/header.vue";
export default {
	components: {
		Footers,
		Headers,
	},
	mounted() {
		const plugin = document.createElement("script");
		plugin.setAttribute("src", "/assets/js/main.js");
		plugin.async = true;
		document.head.appendChild(plugin);
	}
	// created() {
	// 	this.$store.getters.getStruct(this);
	// 	let lang = localStorage.getItem("lang");
	// 	if (lang) {
	// 		this.$store.getters.changeLang(this, lang);
	// 	} else {
	// 		this.$store.getters.changeLang(this, "rus");
	// 	}
	// }
};
</script>
