import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: (h) => h(App),
	// created() {
	// 	window.addEventListener("userLogin", () => {
	// 		location.href = "/admin";
	// 		//location.href = "http://localhost:9527/admin/#/"
	// 	});

	// 	checkingUser().then((response) => {
	// 		console.log(response);
	// 	});
	// },
}).$mount("#app");
