import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "",
		component: () => import("../views/main/index.vue"),
		hidden: true,
	},
	{
		path: "/carousel",
		component: () => import("../views/main/carousel.vue"),
		hidden: true,
	},
	{
		path: "/help",
		component: () => import("../views/clasiffier/help.vue"),
		hidden: true,
		children: [
			{
				path: "about",
				component: () => import("../views/clasiffier/components/about.vue"),
				hidden: true,
			},
			{
				path: "instruction",
				component: () =>
					import("../views/clasiffier/components/instuction.vue"),
			},
			{
				path: "chavo",
				component: () => import("../views/clasiffier/components/chavo.vue"),
			},
		],
	},
	{
		path: "/movie",
		component: () => import("../views/main/components/movie/index.vue"),
	},
	{
		path: "/movie/details/:id",
		component: () => import("../views/main/components/movie/details.vue"),
		hidden: false,
	},
	{
		path: "/movie/actor/:id",
		component: () => import("../views/main/components/movie/actor.vue"),
		hidden: false,
	}
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
});

export default router;
