const state = {
	tableStruct: {},
	eventData: {},
	groupTypeList: [],
};
const getters = {
	getStruct: (state, getters) => (_this) => {
		return new Promise((resolve) => {
			if (state.tableStruct.data) resolve(state.tableStruct);
			_this.$api.get("/apiClient/Main/GetTable").then(
				(response) => {
					state.tableStruct = response.result;
				},
				(err) => _this.$store.getters.errorParse(_this, err)
			);
		});
	},
	getEvent: (state, getters) => (_this, obj) => {
		let query = new URLSearchParams(obj);
		return new Promise((resolve) => {
			_this.$api.get("/apiClient/Main/GetEvent?" + query).then(
				(response) => {
					state.eventData = response.result;
					resolve(response.result);
				},
				(err) => _this.$store.getters.errorParse(_this, err)
			);
		});
	},
	groupTypeList: (state, getters) => (_this, query) => {
		if (query == undefined) query = "";
		_this.$api.get("/apiRef/OrganizationType/Get?" + query).then(
			response => {
				state.groupTypeList = response.result.data;
			},
			err => _this.$store.getters.errorParse(err, _this)
		);
	},
	sendRequest: (state, getters) => (_this, obj) => {
		return new Promise((resolve) => {
			_this.$api.post("/apiClient/Main/RequestClassifier", obj).then(
				(response) => {
					resolve(response.result);
				},
				(err) => _this.$store.getters.errorParse(_this, err)
			);
		});
	},

	toastr: (state, getters) => (_this, type, key, text) => {
		let keyText = _this.$j(key);
		let typeText = _this.$j(text);
		if (keyText == null) { keyText = key; }
		if (typeText == null) { typeText = text; }
		toastr[type](keyText, typeText, {
			progressBar: true,
			timeOut: "5000",
			extendedTimeout: "0"
		});
		return false;
	},

	getFile: (state, getters) => (_this, id, tip) => {
		let selectLang = _this.$store.state.langKod;
		let langId = 1;
		switch (selectLang) {
			case "rus": langId = 2; break;
			case "uzbKr": langId = 3; break;
			case "eng": langId = 4; break;
		}
		var link = document.createElement("a");
		link.href = _this.$store.state.url + "/apiData/MainData/GetByFile?id=" + id + "&fileType=" + tip + "&lang=" + langId;
		link.click();
	},
};
export default {
	state,
	getters,
};